import { TEAMS_URLS, USER_GROUPS } from '@livingsecurity/shared';

export const getRoutes = (t) => [
  {
    id: 'teams',
    title: 'CyberEscape',
    iconClass: 'fa-solid fa-users',
    groups: [USER_GROUPS.TEAMS_LS_ADMIN, USER_GROUPS.TEAMS_CUSTOMER_ADMIN, USER_GROUPS.TEAMS_COLLABORATOR],
    nestedRoutes: [
      {
        title: t('scheduling'),
        path: TEAMS_URLS.scheduling,
        link: TEAMS_URLS.scheduling,
        iconClass: '',
      },
      {
        title: t('leaderboard'),
        path: TEAMS_URLS.leaderboard,
        link: TEAMS_URLS.leaderboard,
        iconClass: '',
      },
      {
        title: t('reports'),
        path: TEAMS_URLS.reports,
        link: TEAMS_URLS.reports,
        iconClass: '',
      },
    ],
  },
  {
    id: 'settings',
    title: t('settings'),
    iconClass: 'far fa-cog',
    groups: [USER_GROUPS.TEAMS_LS_ADMIN, USER_GROUPS.TEAMS_CUSTOMER_ADMIN],
    nestedRoutes: [
      {
        title: t('account'),
        path: TEAMS_URLS.account,
        link: TEAMS_URLS.account,
        iconClass: 'fa-solid fa-users',
      },
      {
        title: t('user-management'),
        path: TEAMS_URLS.users,
        link: TEAMS_URLS.users,
        iconClass: 'fa-solid fa-users',
      },
    ],
  },
  {
    id: 'ls-admin',
    title: t('shared:common:actions-panel:ls-admin-title'),
    iconClass: 'fa-solid fa-crown',
    groups: [USER_GROUPS.TEAMS_LS_ADMIN],
    nestedRoutes: [
      {
        title: t('shared:common:actions-panel:companies'),
        path: TEAMS_URLS.companies,
        link: TEAMS_URLS.companies,
        iconClass: 'fa-solid fa-users',
      },
      {
        title: t('shared:common:actions-panel:ls-admins'),
        path: TEAMS_URLS.lsAdmins,
        link: TEAMS_URLS.lsAdmins,
        iconClass: 'fa-solid fa-users',
      },
      {
        title: t('storylines'),
        path: TEAMS_URLS.storylines,
        link: TEAMS_URLS.storylines,
        iconClass: 'fa-solid fa-users',
      },
    ],
  },
];
