import React, { useCallback, useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  CONTENT_TYPES,
  getMediaValue,
  getPlayerCoverImage,
  useContentfulEntry,
  VideoPlayer,
  SUBTITLES_KEY,
} from '@livingsecurity/shared';

import 'cloudinary-video-player/dist/cld-video-player.css';

const VideoWrapper = styled.div`
  .video-js.vjs-4-3,
  .video-js.vjs-16-9,
  .video-js.vjs-fluid {
    width: 100%;
    max-width: 100%;
    height: 100% !important;
  }

  #cld-video-player {
    opacity: 0;
    transition: 300ms;
  }

  .cld-video-player-dimensions.vjs-fluid {
    padding: 0 !important;
  }

  .vjs-cloudinary-button {
    display: ${({ hideLogo }) => (hideLogo ? 'none !important' : '')};
  }

  width: 100%;
  height: 100%;
`;

const VideoView = ({ contentId, data, type, onPercentChange, playerConfigParams, disableSeeking, refTime, flags }) => {
  const videoPlayerRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { hideVideoPlayerLogo } = flags;
  const videoData = type === CONTENT_TYPES.MODULE ? data.video : data.videoFile?.[0];
  const videoType = type === CONTENT_TYPES.MODULE ? 'video' : 'videoFile';
  const [currentCaptions, setCurrentCaptions] = useState(data?.captionFile);

  const coverImage = useMemo(() => getPlayerCoverImage(data?.coverImage)?.coverImage, [data?.coverImage]);
  const subtitles = localStorage?.getItem(SUBTITLES_KEY);
  const player = videoPlayerRef.current?.getInstance();
  const tracks = player?.videojs?.textTracks();

  if (!data || type !== CONTENT_TYPES.VIDEO) return null;

  // ESD-3222 - If videoData is not available, we need to get the public ID from the English content as a fallback
  const { data: contentData } = useContentfulEntry({
    id: contentId,
    enabled: !videoData || error,
  });

  const englishVideoData = contentData?.fields?.videoFile?.[0];
  const englishPublicId = useMemo(() => getMediaValue(englishVideoData, videoType)?.publicID, [englishVideoData]);

  const publicId = useMemo(() => getMediaValue(videoData, videoType)?.publicID, [data?.video, data?.videoFile]);

  player?.on('error', (error) => {
    setError(true);
  });

  useEffect(() => {
    // On back-to-back videos we need to force the player to re-mount and set new cover image
    onReset(500, data);
  }, [data]);

  const onReset = useCallback(
    (timeout = 1000, data) => {
      setLoading(true);

      if (data?.captionFile !== currentCaptions) {
        setCurrentCaptions(data?.captionFile);
      }

      setTimeout(() => {
        setLoading(false);
      }, timeout);
    },
    [currentCaptions],
  );

  return (
    <VideoWrapper hideLogo={hideVideoPlayerLogo}>
      {!loading && (
        <VideoPlayer
          ref={videoPlayerRef}
          publicId={englishPublicId || publicId}
          captions={currentCaptions}
          onPercentChange={onPercentChange}
          playerConfigParams={playerConfigParams}
          disableSeeking={disableSeeking}
          refTime={refTime}
          width={600}
          onReset={onReset}
          wrapperStyles={{
            width: '97%', // TRAIN-1779 Video is shaking
            height: '100%',
            margin: '0 auto',
          }}
          playerOptions={{
            fluid: true,
            controls: true,
            hideContextMenu: false,
            showJumpControls: true,
            playedEventPercents: [25, 50, 75, 90, 98, 100],
            playbackRates: [0.5, 0.75, 1, 1.5],
            logoOnclickUrl: 'https://livingsecurity.com',
            logoImageUrl: 'https://assets.livingsecurity.com/image/upload/c_scale,h_20/v1692841537/LS-Logo_smmosb.png',
            colors: { base: '#000a10', accent: '#4eb3d4', text: '#fff' },
            posterOptions: {
              publicId: coverImage ? `${coverImage}.jpg` : null,
            },
            ...(playerConfigParams || {}),
          }}
        />
      )}
    </VideoWrapper>
  );
};

VideoView.propTypes = {
  data: PropTypes.shape(),
  type: PropTypes.oneOf(['video', 'videoFile']).isRequired,
  onPercentChange: PropTypes.func,
  playerConfigParams: PropTypes.shape(),
  disableSeeking: PropTypes.bool,
  refTime: PropTypes.number,
};

VideoView.defaultProps = {
  data: null,
  onPercentChange: null,
  playerConfigParams: {},
  disableSeeking: false,
  refTime: 0,
};

export default VideoView;
