import { useQuery } from 'react-query';

import { clientContentful } from '../lib';
import { getMediaValue } from '../utils/custom';

export const getContentfulEntryByType = ({ id, contentType }) =>
  clientContentful.getEntry(id, {
    content_type: contentType,
    include: 1,
  });

export const useContentfulCampaign = ({ id, contentType }) => {
  // const queryClient = useQueryClient();
  const { data, isLoading } = useQuery(['campaign', id], () => getContentfulEntryByType({ id, contentType }), {
    enabled: !!id,
    // onSuccess: (responseData) =>
    //   queryClient.invalidateQueries(['campaignTimeline', responseData?.fields?.platformGuid]),
  });
  const coverImage = data?.fields?.images?.fields?.hero || data?.fields?.coverImage;
  const campaignData = {
    title: data?.fields?.title,
    description: data?.fields?.description,
    difficulty: data?.fields?.difficulty,
    categories: data?.fields?.categories,
    cardBackground: getMediaValue(coverImage)?.url,
    platformCampaignGuid: data?.fields?.platformCampaignGuid,
  };

  return { campaignData, campaignDataIsLoading: isLoading };
};

export const useContentfulEntry = ({ id, contentType, enabled }) => {
  return useQuery(['content', contentType, id], () => getContentfulEntryByType({ id, contentType }), {
    enabled: enabled && !!id,
  });
};
