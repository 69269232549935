import moment from 'moment';
import { tokenService } from '../utils/services';

export default function clientMiddleware(client, clientContentful) {
  return ({ dispatch, getState }) =>
    (next) =>
    (action) => {
      if (typeof action === 'function') {
        return action(dispatch, getState);
      }

      const { promise, types, payload, contentful, ...rest } = action;
      // could be outdated
      const contentfulToken = window.ldClient?.variation('contentful-proxy')
        ? 'xx-xx-xx-xx'
        : process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;

      // Checking for env variable for unit test purposes
      if ((!promise && !contentful) || !contentfulToken) {
        return next(action);
      }

      const [REQUEST, SUCCESS, FAILURE] = types;
      next({ ...rest, ...(payload && { payload }), type: REQUEST });

      const actionPromise = promise ? promise(client, getState) : contentful(clientContentful, getState);
      actionPromise
        .then(
          async (result) => {
            try {
              let lastLog = tokenService.getTokenCheck();

              if (!lastLog || lastLog === 'undefined' || lastLog === 'null') {
                const now = moment().format('X');
                tokenService.recordTokenCheck(now);
                lastLog = now;
              }

              if (lastLog && moment().diff(moment(lastLog, 'X'), 'seconds') > 90) {
                await tokenService.refreshToken();
                tokenService.recordTokenCheck(moment().format('X'));
              }

              next({
                ...rest,
                result,
                type: SUCCESS,
                staticData: getState().staticData,
                ...(payload && { payload }),
              });
            } catch (error) {
              error.meta = { action: SUCCESS };
              throw error;
            }
          },
          (error) => {
            try {
              if (error.response && [401, 403].includes(error?.response?.status)) {
                console.warn('User Unauthorized');
                tokenService?.removeToken();
              }
              next({
                ...rest,
                error,
                type: FAILURE,
                ...(payload && { payload }),
              });
            } catch (e) {
              e.meta = { action: FAILURE };
              throw error;
            }
          },
        )
        .catch((error) => {
          // logout user if expired token or permission denied
          if (error && [401, 403].includes(error?.response?.status)) {
            console.error(`[${error?.response?.status}] ERROR:`, error);
            tokenService?.removeToken();
          } else {
            // eslint-disable-next-line no-console
            console.error('SHARED MIDDLEWARE ERROR:', error);
          }
          next({ ...rest, error, type: FAILURE });
        });

      return actionPromise;
    };
}
