import React from 'react';
import { capitalize } from 'lodash';

import { useSlackInstallStatus, useQueryParams } from '@livingsecurity/shared';

import InfoPage from './components/InfoPage';

const SlackCallback = () => {
  const { id, error: err } = useQueryParams();
  const { data, isError, error } = useSlackInstallStatus({ id });
  const formattedError = err ? capitalize(err?.replace(/_/g, ' ')) : null;

  return (
    <InfoPage
      scope="slack-redirect-view"
      serviceName="Slack"
      isSuccess={data}
      isError={isError && error?.message}
      errorMessage={formattedError || error?.message}
    />
  );
};

export default SlackCallback;
