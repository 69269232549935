import axios from 'axios';
import qs from 'query-string';

import { tokenService } from '@livingsecurity/shared';

const IMPERSONATION_HEADER = 'x-impersonated-tenantid';
const AUTH_TOKEN = 'Authorization';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  paramsSerializer: {
    serialize: (params) => qs.stringify(params, { arrayFormat: 'comma' }),
  },
});

apiClient.interceptors.request.use((config) => {
  const authToken = tokenService.getToken();
  const decodedToken = tokenService.getDecodedToken();
  const tenantNamespace = `${process.env.REACT_APP_USER_CLAIMS_NAMESPACE}/tenant_id`;
  const tenantId = decodedToken?.[tenantNamespace];

  if (authToken) {
    config.headers[AUTH_TOKEN] = `Token ${authToken}`;
  }

  if (tenantId) {
    // This should work but causes a CORS error from the backend
    // config.headers[IMPERSONATION_HEADER] = tenantId;
  }

  return config;
});

export default apiClient;
