import { useEffect } from 'react';
import { useTrackInteraction } from './useTrackInteraction';
import { capitalize } from 'lodash';

/**
 * This hook is used to handle window postMessage API calls from iframes
 */
export const useMessageChannel = (init) => {
  const { track } = useTrackInteraction();

  useEffect(() => {
    if (window.addEventListener) {
      window.addEventListener('message', onMessage, false);
    } else if (window.attachEvent) {
      window.attachEvent('onmessage', onMessage, false);
    }

    function onMessage(event) {
      if (isNotValidDomain(event.origin)) {
        return;
      }
      const data = event?.data;
      if (typeof window[data?.func] === 'function') {
        window[data.func].call(null, data.message);
      } else if (data.type === 'SET_CURRENT_COMPANY') {
        window.setCurrentCompany?.(data.companyName);
      } else if (data.type === 'TRACK_ACTION') {
        track(data.event, data);
      } else if (data.type === 'CONTENT_PREVIEW') {
        window.dispatchEvent(new CustomEvent('showCampaignModal', { detail: data }));
      } else if (data?.type === 'UPDATE_LOCATION' && data.url) {
        if (data.title) {
          document.title = `${data.title} | Living Security ${capitalize(data.app?.toLowerCase())}`;
        }
        window.history.replaceState(data, data.title, `/${data?.app?.toLowerCase() || ''}${data.url}`);
      }
    }

    init();

    return () => {
      window.removeEventListener('message', onMessage);
    };
    // eslint-disable-next-line
  }, [init, track]);
};

// Check sender origin to be trusted
const isNotValidDomain = (origin) => {
  return !origin.includes('livingsecurity.com') && !origin.includes('lsplatform.dev') && !origin.includes('localhost');
};
