import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCompany } from '@livingsecurity/api-client';

import { useAuth0 } from '../lib/react-auth0-spa';
import notification from '../utils/services/notification';
import { USER_GROUPS } from '../_constants';

export const useGetCompany = ({ id, notify = false } = {}) => {
  const { t } = useTranslation('shared');
  const {
    user: { tenant_id: tenantId },
    loading: isUserLoading,
    isUserHasRights,
  } = useAuth0();
  const lsAdmin = isUserHasRights([USER_GROUPS.LS_ADMIN]);

  const { companyServiceV2 } = useFlags();

  const { data, isLoading, error, refetch } = useCompany(
    { id: id ?? tenantId, tenantId },
    {
      useV2: companyServiceV2,
      enabled: !!(tenantId || (id && lsAdmin)),
      onError: () => {
        if (notify) {
          notification.error(t('messages.error.loadCompany'));
        }
      },
    },
  );

  return {
    loading: isUserLoading || isLoading,
    data: data ?? {},
    loadCompany: refetch,
  };
};
