import styled, { css } from 'styled-components';
import COLORS_CONFIG from '../../assets/styles/colors';
import { BUTTON_TYPES } from '../../_constants';
import Loader from '../Loader';

const BUTTON_SIZES = {
  small: '24px',
  medium: '32px',
  large: '48px',
};

const BUTTON_FONT_SIZES = {
  small: '12px',
  medium: '12px',
  large: '16px',
};

const BUTTON_VARIANTS = {
  [BUTTON_TYPES.GREEN]: css`
    background-color: ${COLORS_CONFIG.FEEDBACK.SUCCESS};
    color: ${COLORS_CONFIG.SECONDARY.WHITE};
    font-weight: bold;
  `,
  [BUTTON_TYPES.DANGER]: css`
    background-color: ${COLORS_CONFIG.PRIMARY.POPPY_RED};
    color: ${COLORS_CONFIG.SECONDARY.WHITE};
    font-weight: bold;
  `,
  [BUTTON_TYPES.LS_BLUE]: css`
    background-color: ${COLORS_CONFIG.PRIMARY.LS_BLUE};
    color: ${COLORS_CONFIG.SECONDARY.WHITE};
    font-weight: bold;
  `,
  [BUTTON_TYPES.GREY]: css`
    background-color: ${COLORS_CONFIG.SECONDARY.IRON};
    color: ${COLORS_CONFIG.SECONDARY.WHITE};
    font-weight: bold;
  `,
  [BUTTON_TYPES.SKY]: css`
    background-color: ${COLORS_CONFIG.PRIMARY.LS_CYAN};
    color: ${COLORS_CONFIG.SECONDARY.WHITE};
    font-weight: bold;
  `,
  [BUTTON_TYPES.SKY]: css`
    background-color: ${COLORS_CONFIG.PRIMARY.LS_CYAN};
    color: ${COLORS_CONFIG.SECONDARY.WHITE};
    font-weight: bold;
  `,
  [BUTTON_TYPES.WHITE]: css`
    background-color: ${COLORS_CONFIG.SECONDARY.WHITE};
    color: ${COLORS_CONFIG.PRIMARY.LS_BLACK};
    font-weight: bold;
    border: solid 1px ${COLORS_CONFIG.SECONDARY.CRYSTAL};
  `,
  [BUTTON_TYPES.TRANSPARENT]: css`
    background-color: transparent;
    color: #000;
  `,
  [BUTTON_TYPES.TRANSPARENT_SKY]: css`
    background-color: transparent;
    color: ${COLORS_CONFIG.PRIMARY.LS_CYAN};
  `,
  [BUTTON_TYPES.WITH_BORDER]: css`
    background-color: transparent;
    color: ${COLORS_CONFIG.PRIMARY.LS_BLACK};
    border: solid 1px ${COLORS_CONFIG.PRIMARY.SANDY_GRAY};
    font-weight: bold;
  `,
  [BUTTON_TYPES.OUTLINE_WHITE]: css`
    background-color: transparent;
    color: ${COLORS_CONFIG.SECONDARY.WHITE};
    border: solid 1px ${COLORS_CONFIG.SECONDARY.WHITE};
    font-weight: bold;
  `,
  [BUTTON_TYPES.OUTLINE_SKY]: css`
    background-color: transparent;
    color: ${COLORS_CONFIG.PRIMARY.LS_CYAN};
    border: solid 1px ${COLORS_CONFIG.PRIMARY.LS_CYAN};
    font-weight: bold;
  `,
  [BUTTON_TYPES.DISABLED]: css`
    color: ${COLORS_CONFIG.PRIMARY.LS_BLACK};
    font-weight: bold;
    opacity: 0.4;
    pointer-events: none;
  `,
  DEFAULT: css`
    background-color: ${COLORS_CONFIG.SECONDARY.SKY};
    color: ${COLORS_CONFIG.SECONDARY.DEEP_SEA};
    font-weight: bold;
  `,
  ICON: css`
    background-color: transparent;
    color: inherit;
  `,
};

const leftIconCss = css`
  padding-left: 8px;
  flex-direction: row;
  & > img,
  & > svg {
    flex-shrink: 0;
    margin-right: 8px;
    transition: opacity 0.2s;
  }
`;

const rightIconCss = css`
  padding-right: 8px;
  flex-direction: row-reverse;
  & > img,
  & > svg {
    flex-shrink: 0;
    margin-left: 8px;
    transition: opacity 0.2s;
  }
`;

export const ButtonTitle = styled.span`
  display: block;
  flex-shrink: 1;
  width: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  user-select: none;
`;

export const ButtonWrapper = styled.button`
  height: ${({ size = 'medium' }) => BUTTON_SIZES[size] || '32px'};
  border-radius: 5px;
  padding: ${({ big }) => (big ? '0 25px' : '0 15px')};
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ big, size = 'medium' }) => (big ? '16px' : BUTTON_FONT_SIZES[size] || '12px')};
  position: relative;
  transition: color 0.2s;
  overflow: hidden;
  cursor: pointer;

  &:disabled {
    box-shadow: none;
    ${({ isLoading }) =>
      isLoading
        ? css`
            cursor: progress;
            opacity: 1;
            color: transparent;

            > img,
            > svg {
              opacity: 0;
            }
          `
        : css`
            cursor: not-allowed;
            opacity: 0.5;
          `}
  }

  svg {
    z-index: 1;
  }

  ${({ variant, iconAsButton }) =>
    iconAsButton ? BUTTON_VARIANTS.ICON : BUTTON_VARIANTS[variant] || BUTTON_VARIANTS.DEFAULT}

  ${({ icon, iconAsButton, reverseIcon }) =>
    !!icon &&
    (iconAsButton
      ? css`
          padding: 0;
          height: fit-content;
          & > img {
            margin-right: 0;
            transition: opacity 0.2s;
          }

          & > svg {
            pointer-events: auto;
          }

          &:focus,
          &:focus-visible {
            box-shadow: none;
          }
        `
      : reverseIcon
      ? rightIconCss
      : leftIconCss)}
`;

export const ButtonLoader = styled(Loader)`
  position: absolute;
  padding: 0;
  height: 100% !important; // TODO Need to override other components styles for Loader with higher specificity. Fix those components...
  width: 100%;

  & > img {
    height: 25px;
  }
`;

export const SkeletonWrapper = styled.div`
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: 0;
  width: 100%;
  z-index: 1;

  .react-loading-skeleton {
    display: block;
  }
`;
