/*
* current requests work properly with "httpClient" from "shared"
* could be replaced on "import apiClient from '../apiClient';" in future
*/
import { httpClient } from '@livingsecurity/shared';

// Query Keys
export const CampaignParticipants = {
  getExclusions: (campaignId) => [{ name: `get.campaign.${campaignId}.exclude` }],
};

// Handlers
export const getExcludedParticipants = (tenantId, campaignId) => {
  if (!campaignId) return;
  // eslint-disable-next-line
  return httpClient.get(`/campaigns/${campaignId}/excluded_participants?tenant_id=${tenantId}`);
};
export const getExcludedParticipantsV2 = (tenantId, campaignId) => {
  if (!campaignId) return;
  // eslint-disable-next-line
  return httpClient.get(`/campaigns/v2/campaigns/${campaignId}/excluded_participants?tenant_id=${tenantId}`);
};

export const updateExcludedParticipants = (tenantId, campaignId, userIds) => {
  if (!campaignId) return;
  // eslint-disable-next-line
  return httpClient.post(`/campaigns/${campaignId}/excluded_participants?tenant_id=${tenantId}`, { userIds });
};
export const updateExcludedParticipantsV2 = (tenantId, campaignId, userIds) => {
  if (!campaignId) return;
  // eslint-disable-next-line
  return httpClient.post(`/campaigns/v2/campaigns/${campaignId}/excluded_participants?tenant_id=${tenantId}`, { user_ids: userIds });
};
