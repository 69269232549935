import React from 'react';
import PropTypes from 'prop-types';

import { ErrorBoundary } from '@livingsecurity/shared';

const InfoPage = ({
  scope,
  serviceName,
  isSuccess,
  isError,
  errorMessage,
}) => (
  <ErrorBoundary scope={scope}>
      <div
        style={{
          width: '100%',
          height: '100vh',
          background: 'white',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'center',
        }}
      >
        <img
          src="https://assets.livingsecurity.com/image/upload/v1679943802/logo-vertical_ptamuz_ilzd5d.png"
          alt="Living Security Logo"
          style={{ width: '250px', marginTop: '-100px' }}
        />
        <div style={{ textAlign: 'center' }}>
          {isSuccess ? (
            <>
              <h1 style={{ fontSize: '32px', textAlign: 'center' }}>Living Security Training was successfully installed on {serviceName}</h1>
              <p style={{ fontSize: '16px', marginTop: '12px' }}>
                Redirect to the <a href="https://app.livingsecurity.com/account/config">Living Security dashboard</a>
              </p>
            </>
          ) : isError ? (
            <>
              <h1 style={{ fontSize: '32px', textAlign: 'center', color: 'red', fontWeight: 600 }}>ERROR</h1>
              <p style={{ fontSize: '16px', marginTop: '12px' }}>{`Reason: ${errorMessage}`}</p>
              <p style={{ fontSize: '16px', marginTop: '8px' }}>
                Please contact our{' '}
                <a href="https://www.livingsecurity.com/support/contact-support" target="_blank" rel="noreferrer"
                   style={{ color: '#4eb3d4' }}>
                  support team
                </a>{' '}
                if you need assistance
              </p>
            </>
          ) : (
            <>
              <h1 style={{ fontSize: '32px', textAlign: 'center' }}>Installing Living Security Training on {serviceName}...</h1>
              <p style={{ fontSize: '16px', marginTop: '12px' }}>This page will reload automatically</p>
            </>
          )}
        </div>
        <div style={{ fontSize: '10px', color: '#4eb3d4', marginTop: '120px' }}>
          <a href="https://www.livingsecurity.com/data-protection" style={{ fontWeight: 500 }}>
            Privacy Policy
          </a>
        </div>
      </div>
    </ErrorBoundary>
);

InfoPage.propTypes = {
  scope: PropTypes.string.isRequired,
  serviceName: PropTypes.string.isRequired,
  isSuccess: PropTypes.string,
  isError: PropTypes.string,
  errorMessage: PropTypes.string,
};

InfoPage.defaultProps = {
  isSuccess: false,
  isError: false,
  errorMessage: null,
};

export default InfoPage;
