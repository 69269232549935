import { useQuery } from 'react-query';

import { httpClient } from '../lib';

const MS_TEAMS_QUERY_KEY = 'MS_TEAMS_QUERY_KEY';

const getCompanyIntegrations = (tenantId) => httpClient.get(`/connections/connections/apps?tenant_id=${tenantId}`);

export const getMSTeamsApprovalStatus = (tenantId) => httpClient.get(`/ms-teams/consent_status?tenant_id=${tenantId}`);

export const getIntegrationInstallData = (tenantId = '') =>
  httpClient.get(`/ms-teams/installations?tenant_id=${tenantId}`);

// https://github.com/TanStack/query/discussions/937
export const useCompanyIntegrations = (tenantId = '', onSuccess) =>
  useQuery(MS_TEAMS_QUERY_KEY, () => getCompanyIntegrations(tenantId), {
    onSuccess,
  });

export const updateCompanyIntegration = ({ tenantId, appId, status }) =>
  httpClient.post(`/connections/connections/apps?tenant_id=${tenantId}`, {
    app_id: appId,
    status,
  });

export const getAuthorizationEndpointUrl = (tenantId) =>
  httpClient.get(`/ms-teams/consent_redirect_url?tenant_id=${tenantId}`);

export const useMSTeamsInstallStatus = (tenantId = '') => {
  const { data, isSuccess, isError, error } = useQuery(
    `${MS_TEAMS_QUERY_KEY}_STATUS`,
    () => getIntegrationInstallData(tenantId),
    {
      refetchInterval: (result) => (result?.installs ? false : 15000),
    },
  );

  return {
    isSuccess: isSuccess && data?.installs,
    isError,
    error,
  };
};
