import React, { useState, useCallback, useEffect } from 'react';
import { PromptMenu } from './PromptMenu';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

const CONVO_TAB = 'CONVO';
const PROMPT_TAB = 'PROMPTS';

export const ChatMenu = ({
  sidebarOpen,
  toggleSidebar,
  embedded,
  prompts,
  filterPrompts,
  promptTrigger,
  userConversations,
  setActiveConversation,
  refetchConversations,
}) => {
  const { t } = useTranslation('chatbot');
  const [activeTab, setActiveTab] = useState(CONVO_TAB);

  useEffect(() => {
    if (sidebarOpen) {
      refetchConversations();
    }
  }, [sidebarOpen]);

  const selectConversation = useCallback(
    (conversation) => {
      setActiveConversation(conversation);
      if (embedded) {
        toggleSidebar();
      }
    },
    [embedded, setActiveConversation, toggleSidebar],
  );

  return (
    <div
      className={`${
        sidebarOpen ? (embedded ? 'w-full absolute' : 'w-1/4') : 'hidden'
      } bg-neutral/95 backdrop-blur-lg flex flex-col py-3 transition ease-in-out delay-400 overflow-x-hidden shadow-3xl left-0 right-0 top-0 bottom-0 z-100`}
    >
      <div className="flex flex-col items-center">
        <div className="tabs tabs-boxed">
          <a className={`tab ${activeTab === CONVO_TAB && 'tab-active'}`} onClick={() => setActiveTab(CONVO_TAB)}>
            {t('menu.conversations')}
          </a>
          <a className={`tab ${activeTab === PROMPT_TAB && 'tab-active'}`} onClick={() => setActiveTab(PROMPT_TAB)}>
            {t('menu.prompts')}
          </a>
        </div>
        {activeTab === CONVO_TAB && (
          <div className="relative w-full">
            <div
              className="absolute top-[-38px] right-1"
              onClick={() =>
                selectConversation({
                  conversationId: uuidv4(),
                  title: t('menu.new-conversation'),
                  messages: [],
                })
              }
            >
              <div className="btn btn-primary btn-sm text-white font-light text-sm">
                <i className="fa-solid fa-plus" />
              </div>
            </div>
            <div className="mt-3 w-full overflow-x-hidden mb-4">
              {userConversations?.length ? (
                userConversations.slice(0, 5).map((conversation, index) => {
                  const msgs = conversation?.messages.filter((msg) => msg.role === 'user') || [];
                  return (
                    <div
                      className="flex flex-row items-center p-2 cursor-pointer hover:bg-white/10"
                      key={index}
                      onClick={() => selectConversation(conversation)}
                    >
                      <div className="flex flex-col items-start w-full">
                        <p className="text-[15px] font-bold text-white">{conversation.title}</p>
                        <p className="text-md mt-1">
                          {conversation.summary || msgs?.[msgs.length - 1]?.content || t('menu.no-messages')}
                        </p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="flex justify-center mt-4 w-full">{t('menu.no-conversations')}</div>
              )}
            </div>
          </div>
        )}
        {activeTab === PROMPT_TAB && (
          <PromptMenu prompts={prompts} filterPrompts={filterPrompts} promptTrigger={promptTrigger} />
        )}
      </div>
    </div>
  );
};
