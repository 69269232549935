import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAuth0, ErrorBoundary, USER_GROUPS, Iframe } from '@livingsecurity/shared';

import { AuthorizedLayout } from 'components';

const ApiAuthentication = ({ history }) => {
  const { isUserHasRights } = useAuth0();
  const { unifyUrl } = useFlags();
  const [currentUrl, setCurrentUrl] = useState(unifyUrl);
  const hasAccess = isUserHasRights([
    USER_GROUPS.LS_ADMIN,
    USER_GROUPS.UNIFY_LS_ADMIN,
    USER_GROUPS.ENTERPRISE_ADMIN,
    USER_GROUPS.UNIFY_CUSTOMER_ADMIN,
    USER_GROUPS.CUSTOMER_ADMIN,
  ]);

  useEffect(() => {
    if (window.location?.href?.includes('livingsecurity.com')) {
      window.document.domain = 'livingsecurity.com';
    }
  }, []);

  useEffect(() => {
    if (!hasAccess) {
      history.replace('/');
    }
  }, [hasAccess, history]);

  const onLoaded = useCallback(() => {
    if (!currentUrl.includes('settings/api')) {
      const monorepo = !!unifyUrl?.includes('unify-dev') || !!unifyUrl?.includes('unify-eu');
      setCurrentUrl(`${unifyUrl}/${monorepo ? 'unify/settings/api' : 'settings/api'}`);
    }
  }, [currentUrl, unifyUrl]);

  return (
    <>
      <AuthorizedLayout app="API_AUTHENTICATION" hideSidebar withBottomPaddingForHubspot>
        <ErrorBoundary scope="api-authentication">
          <Iframe
            id="platform-api-authentication-frame"
            allow="clipboard-read; clipboard-write *;"
            className="embedded-iframe"
            data-testid="platform-api-authentication"
            title="Living Security API Authentication"
            src={currentUrl}
            onLoad={onLoaded}
          />
        </ErrorBoundary>
      </AuthorizedLayout>
    </>
  );
};

ApiAuthentication.propTypes = {
  history: PropTypes.any.isRequired,
};

export default ApiAuthentication;
