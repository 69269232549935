import React, { lazy } from 'react';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeedbackModal, LazyLoad, useToggle, useAuth0, USER_GROUPS } from '@livingsecurity/shared';

import { AuthorizedLayout } from 'components';

const UserManagementComponentPkg = lazy(() => import('@livingsecurity/user-management'));

const UserManagement = (props) => {
  const { isUserHasRights } = useAuth0();
  const { t } = useTranslation('shared');
  const [feedbackModalOpen, toggleFeedbackModal] = useToggle(false);
  const isCustomerAdmin = isUserHasRights([USER_GROUPS.CUSTOMER_ADMIN]);
  const hideSidebar = isUserHasRights([
    USER_GROUPS.LS_ADMIN,
    USER_GROUPS.UNIFY_LS_ADMIN,
    USER_GROUPS.ENTERPRISE_ADMIN,
    USER_GROUPS.UNIFY_CUSTOMER_ADMIN,
    USER_GROUPS.UNIFY_CUSTOMER_LIMITED_ADMIN,
    USER_GROUPS.PHISHING_CUSTOMER_ADMIN,
    USER_GROUPS.PHISHING_LS_ADMIN,
  ]);

  return (
    <>
      {feedbackModalOpen && (
        <FeedbackModal content={t('messages.downloads.notification.success')} onClose={() => toggleFeedbackModal()} />
      )}
      <AuthorizedLayout withBottomPaddingForHubspot hideSidebar={hideSidebar && !isCustomerAdmin}>
        <LazyLoad component={UserManagementComponentPkg} toggleFeedbackModal={toggleFeedbackModal} {...props} />
      </AuthorizedLayout>
    </>
  );
};

export default UserManagement;
