import { useCallback } from 'react';
import { Userpilot } from 'userpilot';
import axios from 'axios';

import { useAuth0 } from '../lib';
import { useGetCompany } from '.';

const METRICS_URL = process.env.REACT_APP_NEXT_API;

/**
 *  Track user interactions and send them to tracking tools
 */
export const useTrackInteraction = () => {
  const { user, isLsAdmin, isReseller, isCompanyAdmin } = useAuth0();

  const { data: company } = useGetCompany();

  const track = useCallback(
    async (event, metadata = {}, services = SERVICES) => {
      const t = transformMetadata(event, metadata);

      if (process.env.REACT_APP_DEBUG === 'true') console.log(`TRACK EVENT: ${t.event}`, t.metadata);

      if (services.includes('USER_PILOT')) {
        Userpilot.track(t.event, {
          name: user?.name,
          email: user?.email,
          company: company?.name,
          ...(t.metadata || {}),
        });
      }
      if (services.includes('VITALLY') && isCompanyAdmin && !isLsAdmin && !isReseller && METRICS_URL) {
        await axios.post(`${METRICS_URL}/metric`, {
          event: t.event || event,
          userId: user?.email || metadata?.userId || user?.sub,
          accountId: user?.tenant_id || company?.id || metadata?.company?.id,
          timestamp: new Date().toISOString(),
          properties: {
            email: user?.email || metadata?.email,
            company: company?.name || metadata?.company?.name,
            ...(t.metadata || {}),
          },
        });
      }
    },
    [
      isCompanyAdmin,
      isLsAdmin,
      isReseller,
      user?.name,
      user?.email,
      company?.id,
      company?.name,
      user?.sub,
      user?.tenant_id,
    ],
  );

  return {
    track,
  };
};

const transformMetadata = (event, metadata) => {
  switch (event) {
    case EVENTS.CREATE_ACTION_PLAN:
      return {
        event: FEATURE_TRACK.CREATE_ACTION_PLAN,
        metadata: {
          actionPlanType: metadata.actionPlanType,
          audienceSize: metadata.audienceSize,
          ...(metadata || {}),
        },
      };
    case EVENTS.CREATE_LENS:
      return {
        event: FEATURE_TRACK.CREATE_LENS,
        metadata: {
          name: metadata.name,
          description: metadata.description,
          ...(metadata || {}),
        },
      };
    case EVENTS.CREATE_INTEGRATION:
      return {
        event: FEATURE_TRACK.CREATE_INTEGRATION,
        metadata: {
          name: metadata.name,
          ...(metadata || {}),
        },
      };
    case EVENTS.CLICK_NAVBAR_ITEM:
      return {
        event: `${FEATURE_TRACK.CLICK_NAVBAR_ITEM}: ${metadata.itemName}`,
        metadata: {
          itemName: metadata.itemName,
        },
      };
    case EVENTS.EXCLUDE_USER_CAMPAIGN:
      return {
        event: FEATURE_TRACK.EXCLUDE_USER_CAMPAIGN,
        metadata: {
          campaignId: metadata.campaignId,
          userId: metadata.userId,
        },
      };
    case EVENTS.PAGE_VIEW:
      return {
        event: `${FEATURE_TRACK.PAGE_VIEW} ${metadata.name}`,
        metadata,
      };
    case EVENTS.TOGGLE_INTEGRATION:
      return {
        event: `${FEATURE_TRACK.TOGGLE_INTEGRATION} ${metadata.name} Integration ${metadata.status}`,
        metadata,
      };
    default:
      return {
        event: FEATURE_TRACK[event] || event,
        metadata,
      };
  }
};

export const EVENTS = {
  USER_LOGIN: 'USER_LOGIN',
  // Participant
  SHARE_CERTIFICATE_LINKEDIN: 'SHARE_CERTIFICATE_LINKEDIN',
  CLICK_NAVBAR_ITEM: 'CLICK_NAVBAR_ITEM',
  // Chatbot
  CHATBOT_VIEW: 'CHATBOT_VIEW',
  // Unify
  CREATE_ACTION_PLAN: 'CREATE_ACTION_PLAN',
  CREATE_RECOMMENDED_ACTION_PLAN: 'CREATE_RECOMMENDED_ACTION_PLAN',
  CREATE_LENS: 'CREATE_LENS',
  CREATE_INTEGRATION: 'CREATE_INTEGRATION',
  CREATE_WORKFLOW: 'CREATE_WORKFLOW',
  // Training
  TOGGLE_INTEGRATION: 'TOGGLE_INTEGRATION',
  LAUNCH_CAMPAIGN: 'LAUNCH_CAMPAIGN',
  CREATE_AUDIENCE: 'CREATE_AUDIENCE',
  CREATE_NOTIFICATION: 'CREATE_NOTIFICATION',
  DOWNLOAD_REPORT: 'DOWNLOAD_REPORT',
  EXCLUDE_USER_CAMPAIGN: 'EXCLUDE_USER_CAMPAIGN',
  // Page Views
  PAGE_VIEW: 'PAGE_VIEW',
  VIEW_USER_MANAGEMENT: 'VIEW_USER_MANAGEMENT',
  VIEW_COMPANY_MANAGEMENT: 'VIEW_COMPANY_MANAGEMENT',
  VIEW_COMMUNITY: 'VIEW_COMMUNITY',
  VIEW_UNIFY: 'VIEW_UNIFY',
  VIEW_TEAMS_ADMIN: 'VIEW_TEAMS_ADMIN',
  VIEW_CHATBOT: 'VIEW_CHATBOT',
  VIEW_PHISHING: 'VIEW_PHISHING',
  VIEW_TRAINING: 'VIEW_TRAINING',
  VIEW_SUPPORT: 'VIEW_SUPPORT',
  // SCORM
  UPLOAD_SCORM: 'UPLOAD_SCORM',
};

export const FEATURE_TRACK = {
  USER_LOGIN: 'User Login',
  // Participant
  SHARE_CERTIFICATE_LINKEDIN: 'Shared Certificate to LinkedIn',
  CLICK_NAVBAR_ITEM: 'Participant Clicked Navbar',
  // Chatbot
  CHATBOT_VIEW: 'Opened Chat Slideout',
  // Unify
  CREATE_ACTION_PLAN: 'Created Action Plan',
  CREATE_RECOMMENDED_ACTION_PLAN: 'Created Recommended Action Plan',
  CREATE_LENS: 'Created Lens',
  CREATE_INTEGRATION: 'Created Integration',
  CREATE_WORKFLOW: 'Created Workflow',
  // Training
  TOGGLE_INTEGRATION: 'Toggled',
  LAUNCH_CAMPAIGN: 'Launched Campaign',
  CREATE_AUDIENCE: 'Created Audience',
  CREATE_NOTIFICATION: 'Created Notification',
  DOWNLOAD_REPORT: 'Downloaded Report',
  EXCLUDE_USER_CAMPAIGN: 'Excluded User from Campaign',
  // Page Views
  PAGE_VIEW: 'Navigate to',
  VIEW_USER_MANAGEMENT: 'App Switcher Navigated to User Management',
  VIEW_COMPANY_MANAGEMENT: 'App Switcher Navigated to Company Management',
  VIEW_COMMUNITY: 'App Switcher Navigated to Community',
  VIEW_UNIFY: 'App Switcher Navigated to Unify',
  VIEW_TEAMS_ADMIN: 'App Switcher Navigated to Teams',
  VIEW_CHATBOT: 'App Switcher Navigated to Chatbot',
  VIEW_PHISHING: 'App Switcher Navigated to Phishing',
  VIEW_TRAINING: 'App Switcher Navigated to Training',
  VIEW_SUPPORT: 'App Switcher Navigated to Support Garden',
  // SCORM
  UPLOAD_SCORM: 'Uploaded Scorm File',
};

export const SERVICES = ['USER_PILOT', 'VITALLY'];
