import { useMutation } from 'react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { httpClient } from '../lib';

const addToCampaign = ({ campaignGuid = '', test, name, tenantId }) =>
  httpClient.post(`/campaigns/contentful/${campaignGuid}${tenantId ? `?tenant_id=${tenantId}` : ''}`, { test, name });
const addToCampaignV2 = ({ campaignGuid = '', test, name, tenantId }) =>
  httpClient.post(`/campaigns/v2/contentful/${campaignGuid}${tenantId ? `?tenant_id=${tenantId}` : ''}`, { test, name });

const validateCampaignName = ({ name, tenantId }) =>
  httpClient.post(`/campaigns/check_name?tenant_id=${tenantId}`, { name });
const validateCampaignNameV2 = ({ name, tenantId }) =>
  httpClient.post(`/campaigns/v2/check_name?tenant_id=${tenantId}`, { name });

export const useValidateCampaignName = (queryOptions = {}) => {
  const { campaignServiceV2 } = useFlags();

  return useMutation(({ name = '', tenantId = '' }) => {
    return (campaignServiceV2 ? validateCampaignNameV2 : validateCampaignName)({ name, tenantId });
  }, queryOptions);
};

export const useAddToCampaign = (mutationOptions = {}) => {
  const { campaignServiceV2 } = useFlags();

  return useMutation(({ campaignGuid = '', test, name, tenantId }) => {
    return (campaignServiceV2 ? addToCampaignV2 : addToCampaign)({ campaignGuid, test, name, tenantId });
  }, mutationOptions);
};
