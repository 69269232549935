import React from 'react';
import { capitalize } from 'lodash';

import { useQueryParams, useMSTeamsInstallStatus, useAuth0 } from '@livingsecurity/shared';

import InfoPage from './components/InfoPage';

// TODO: need testing
const MSTeamsCallback = () => {
  const {
    user: { tenant_id },
  } = useAuth0();
  const { error: queryError } = useQueryParams();
  const { isSuccess, isError, error: requestError } = useMSTeamsInstallStatus(tenant_id);
  const formattedError = queryError ? capitalize(queryError.replace(/_/g, ' ')) : null;

  return (
    <InfoPage
      scope="ms-teams-redirect-view"
      serviceName="Microsoft Teams"
      isSuccess={isSuccess}
      isError={isError && requestError?.message}
      errorMessage={formattedError || requestError?.message}
    />
  );
};

export default MSTeamsCallback;
