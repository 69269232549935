import apiClient from '../apiClient';

export const getCompany = async (id, tenantId) => {
  const res = await apiClient.get(`/companies/companies/${id}?tenant_id=${tenantId}`);
  return res.data;
};

export const getCompanyV2 = async (id, tenantId) => {
  const res = await apiClient.get(`/companies/v2/companies/${id}?tenant_id=${tenantId}`);
  return res.data;
};
