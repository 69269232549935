import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  tokenService,
  useAuth0,
  useMessageChannel,
  CampaignDetailsModal,
  useContentfulCampaign,
} from '@livingsecurity/shared';
import Chatbot from 'containers/Chatbot';
import { AuthEntity } from '_entities';
import Sidebar, { APP_TYPE } from '../../blocks/Sidebar';
import Navbar from '../../blocks/Navbar';
import * as Styled from './styles';

const AuthorizedLayout = ({ children, withoutPadding, withBottomPaddingForHubspot, hideSidebar, app, noScroll }) => {
  const dispatch = useDispatch();
  const [tokenStatus, setTokenStatus] = useState(false);
  const [iframeRoute, setIFrameRoute] = useState();
  const [iframeCompany, setIFrameCompany] = useState();
  const [selectedEntry, setCampaignModalData] = useState(null);
  const { getIdTokenClaims, logout, isImpersonate, isLsAdmin, isCompanyAdmin } = useAuth0();
  const flags = useFlags();
  const chatbotStatus = useMemo(
    () => (!isLsAdmin && isCompanyAdmin) || (isLsAdmin && isImpersonate),
    [isLsAdmin, isCompanyAdmin, isImpersonate],
  );
  const [chatSlideoutOpen, setChatSlideoutOpen] = useState(sessionStorage?.getItem('UNIFY_ASSIST_OPEN') === 'true');

  useMessageChannel(() => {
    function setCurrentRoute(message) {
      setIFrameRoute(message);
    }

    function setCurrentCompany(message) {
      setIFrameCompany(message);
    }

    window.setCurrentRoute = setCurrentRoute;
    window.setCurrentCompany = setCurrentCompany;
  });

  const { campaignData, campaignDataIsLoading } = useContentfulCampaign({
    id: selectedEntry?.id,
    contentType: 'campaign',
  });

  useEffect(() => {
    const showCampaignModal = (e) => {
      setCampaignModalData({
        cardBackground: '',
        ...(e.detail?.campaignTemplate || {}),
      });
    };
    window.addEventListener('showCampaignModal', showCampaignModal);
    return () => {
      window.removeEventListener('showCampaignModal', showCampaignModal);
    };
  }, []);

  useEffect(() => {
    // TODO: move logic of setting token and userId to separate util
    const getUserToken = async () => {
      try {
        const claims = await getIdTokenClaims();
        const token = claims?.__raw || tokenService.getToken();

        tokenService.manageToken(token);
        setTokenStatus(true);
      } catch (error) {
        // eslint-disable-next-line
        logout();
      }
    };

    getUserToken();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (chatbotStatus && chatSlideoutOpen) {
      setChatSlideoutOpen(true);
    } else {
      setChatSlideoutOpen(false);
    }
  }, [chatbotStatus, chatSlideoutOpen]);

  const renderCampaignModal = () => {
    if (selectedEntry && campaignData) {
      return (
        <CampaignDetailsModal
          open={!!selectedEntry}
          onClose={() => setCampaignModalData(null)}
          contentType="campaign"
          {...selectedEntry}
          locales={[]}
          campaignData={campaignData}
          campaignDataIsLoading={campaignDataIsLoading}
          hideAddCampaignButton
        />
      );
    }
    return null;
  };

  return (
    <Styled.Struct>
      <Navbar
        flags={flags}
        iframeCompanyName={iframeCompany}
        dispatch={dispatch}
        chatSlideoutOpen={chatSlideoutOpen}
        toggleChatSlideout={() => setChatSlideoutOpen(!chatSlideoutOpen)}
        height="57px"
      />
      <Styled.Container>
        {!hideSidebar && <Sidebar app={app} tokenStatus={tokenStatus} currentRoute={iframeRoute} />}
        <Styled.ContentWrapper>
          <Styled.ContentBlock>
            <Styled.Content
              withoutPadding={withoutPadding}
              withBottomPaddingForHubspot={withBottomPaddingForHubspot}
              noScroll={noScroll}
            >
              {children}
            </Styled.Content>
          </Styled.ContentBlock>
        </Styled.ContentWrapper>
        {chatSlideoutOpen && (
          <div style={{ background: 'black', width: '550px', minWidth: '550px', color: 'white' }}>
            <Chatbot standalone />
          </div>
        )}
        {renderCampaignModal()}
      </Styled.Container>
    </Styled.Struct>
  );
};

AuthorizedLayout.propTypes = {
  app: PropTypes.string,
  withoutPadding: PropTypes.bool,
  withBottomPaddingForHubspot: PropTypes.bool,
  hideSidebar: PropTypes.bool,
};

AuthorizedLayout.defaultProps = {
  app: APP_TYPE.TRAINING,
  withoutPadding: false,
  withBottomPaddingForHubspot: false,
  hideSidebar: false,
};

export default AuthorizedLayout;
