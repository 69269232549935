import axios from 'axios';
import qs from 'query-string';

import { tokenService } from '../utils/services';
import { AUTH_TOKEN } from '../_constants';

const IMPERSONATION_HEADER = 'X-IMPERSONATED-TENANTID';

const _API = axios.create({
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'comma' }),
  // withCredentials: true
});

_API.interceptors.response.use(
  (response) => {
    if (response?.data?.token && (response.config.url.includes('auth') || response.config.url.includes('bootstrap'))) {
      tokenService.manageToken(response.data.token);
    }
    return Promise.resolve(response.data);
  },
  (error) => {
    const errorText = error?.response?.statusText.length ? error?.response?.statusText : error?.stack;
    const responseData = error?.response?.data;

    if (error?.response?.status === 404) {
      console.warn(`${error?.response?.status} Status Warning:`, responseData || errorText);
    } else {
      console.error(`API ERROR (${error?.response?.status}): `, responseData || errorText);
    }

    return Promise.reject(error);
  },
);

/**
 * Add API_URL to request url before sending request.
 */
_API.interceptors.request.use((config) => {
  const authToken = tokenService.getToken();
  const decodedToken = tokenService.getDecodedToken();
  const tenantNamespace = `${process.env.REACT_APP_USER_CLAIMS_NAMESPACE}/tenant_id`;
  const tenantId = decodedToken?.[tenantNamespace];

  if (authToken) config.headers[AUTH_TOKEN] = `Token ${authToken}`;
  // if (tenantId) config.headers[IMPERSONATION_HEADER] = tenantId;

  config.url = `${process.env.REACT_APP_API_URL}${config.url}`;
  // config.withCredentials = true;
  return config;
});

export default _API;
