/* Libs */
import styled, { css } from 'styled-components';
import { Link as DefaultLink } from 'react-router-dom';
import { ButtonStyles, ImgStyles, BREAKPOINTS } from '@livingsecurity/shared';

export const ContentWrapper = styled.div`
  overflow-x: hidden;
  padding: 0 18px 20px 0;
  margin-right: -18px;
  max-height: calc(100vh - 105px);
`;

export const Subtitle = styled.div`
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: bold;
  color: var(--dark-blue-grey);
  text-transform: uppercase;
`;

export const List = styled.ul``;

export const Link = styled(DefaultLink)`
  display: flex;
  align-items: center;
  width: 100%;
  color: var(--dark-blue-grey);
  font-weight: 300;
  text-decoration: none;

  & > ${ImgStyles.Image} {
    margin: 0 5px 0 0;
  }

  &:hover {
    font-weight: normal;
    text-decoration: none;
  }

  &:active {
    text-decoration: none;
  }
`;

export const ListItem = styled.li`
  font-size: 16px;
  color: var(--dark-blue-grey);
  padding: ${({ withPadding }) => (withPadding ? '0 10px' : '0')};

  &:not(:first-child) {
    margin: 14px 0 0;
  }

  ${Link} {
    ${({ isActiveRoute }) =>
      isActiveRoute &&
      css`
        font-weight: bold;
      `};
  }

  a:hover {
    color: var(--sky);
  }
`;

export const Trigger = styled.div`
  position: absolute;
  top: 32px;
  right: -12px;
  z-index: 1;
  height: 24px;
  box-shadow: 0 1px 5px 0 var(--black-two33);
  border: solid 0 var(--dark-blue-grey);
  border-radius: 50%;
  background-color: var(--white);
  cursor: pointer;
`;

export const TriggerImage = styled.img`
  transition: transform 300ms;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(0deg)' : 'rotate(180deg)')};
`;

export const Wrapper = styled.div`
  padding: 37px 18px;
  flex-shrink: 0;
  width: 240px;
  position: relative;
  z-index: 2;
  margin: 0 0 0 ${({ isOpen }) => (isOpen ? '0' : '-225px')};
  transition: margin 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
  box-shadow: 5px 6px 10px 0 ${({ dark }) => (dark ? 'var(--black-two20)' : 'var(--light-blue-grey)')};
  background-color: ${({ dark }) => (dark ? 'var(--dark-blue-two)' : 'var(--ice-blue-two)')};

  ${({ dark }) =>
    dark &&
    css`
      ${ListItem} {
        img {
          filter: brightness(4);
        }

        ${Link} {
          color: var(--white);
        }
      }

      ${Subtitle} {
        color: var(--white);
      }
    `};

  @media (max-width: ${BREAKPOINTS.SM_LAPTOP}) {
    padding: 30px 8px;
    width: 220px;
    margin: 0 0 0 ${({ isOpen }) => (isOpen ? '0' : '-208px')};
  }
`;

export const ImpersonateBtn = styled.div`
  margin-top: 30px;
  ${ButtonStyles.ButtonWrapper} {
    height: 24px;
    width: 100%;
    color: var(--white);
    background-color: var(--grey);
  }
`;
