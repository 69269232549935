import { createClient } from 'contentful';

const newClientParams = {
  space: 'xx-xx-xx-xx',
  accessToken: 'xx-xx-xx-xx',
  host: process.env.REACT_APP_CONTENTFUL_HOST || 'dev-api.livingsecurity.com',
  basePath: 'content/search',
};

const oldClientParams = {
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID || 'TEST',
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN || 'TEST',
  environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT || 'TEST',
};

const createContentfulClient = () => {
  const clientParams = window.ldClient?.variation('contentful-proxy') ? newClientParams : oldClientParams;

  return createClient(clientParams);
};

let cachedClient = createContentfulClient();
let cachedFlag = window.ldClient?.variation('contentful-proxy');

const clientContentful = new Proxy(
  {},
  {
    get(target, prop) {
      if (cachedFlag !== window.ldClient?.variation('contentful-proxy')) {
        cachedFlag = window.ldClient?.variation('contentful-proxy');
        cachedClient = createContentfulClient();
      }
      return cachedClient[prop];
    },
    set() {
      return false;
    },
  },
);

export default clientContentful;
