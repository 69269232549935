import { useQuery } from 'react-query';
import { getCompany, getCompanyV2 } from '../api/company';

export const useCompany = ({ id, tenantId }, options = {}) => {
  const { useV2 = false, ...queryOptions } = options;

  return useQuery(
    ['company', id, tenantId, useV2],
    () => (useV2 ? getCompanyV2(id, tenantId) : getCompany(id, tenantId)),
    {
      ...queryOptions,
    },
  );
};
