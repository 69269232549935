import { useQuery, useMutation } from 'react-query';
import { httpClient } from '../lib';

const getSlackStatus = ({ tenantId = '' }) => httpClient.get(`/slack-native/slack/integration?tenant_id=${tenantId}`);

const updateSlackStatus = ({ tenantId = '', body = {} }) =>
  httpClient.post(`/slack-native/slack/integration?tenant_id=${tenantId}`, body);

const getSlackInstallStatus = ({ id = '' }) => httpClient.get(`/slack-native/slack/install/status?id=${id}`);

export const useSlackStatus = ({ tenantId = '', queryKey = [] }) => {
  const { data, isLoading, isError, isSuccess, error } = useQuery(queryKey, () => getSlackStatus({ tenantId }), {
    refetchInterval: (result) => {
      if (result?.status === 'enabled' && result?.hasInstallations === false) {
        return 60000;
      }
      return false;
    },
  });

  return {
    hasInstallations: data?.hasInstallations,
    status: data?.status,
    isStatusLoading: isLoading,
    isStatusError: isError || data?.creation_status === 'failed',
    isStatusSuccess: isSuccess,
    statusError: error,
  };
};

export const useUpdateSlackStatus = (mutationOptions = {}) => {
  return useMutation(({ tenantId = '', body = {} }) => {
    return updateSlackStatus({ tenantId, body });
  }, mutationOptions);
};

export const useSlackInstallStatus = ({ id }) => {
  const { data, isLoading, isError, isSuccess, error } = useQuery(``, () => getSlackInstallStatus({ id }), {
    refetchInterval: (result) => {
      if (result && result?.status !== 'enabled' && id) {
        return 15000;
      }
      return false;
    },
  });

  return {
    data,
    isLoading,
    isError,
    isSuccess,
    error,
  };
};
