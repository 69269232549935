import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useAuth0, ErrorBoundary, USER_GROUPS, Iframe, MARKETING_PAGE, UNIFY_URLS } from '@livingsecurity/shared';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { AuthorizedLayout } from 'components';

const UnifyDashboard = ({ history }) => {
  const { isUserHasRights } = useAuth0();
  const hasAccess = isUserHasRights([
    USER_GROUPS.UNIFY_CUSTOMER_ADMIN,
    USER_GROUPS.UNIFY_CUSTOMER_LIMITED_ADMIN,
    USER_GROUPS.UNIFY_LS_ADMIN,
  ]);
  const { unifyUrl } = useFlags();
  const { pathname } = useLocation();
  const [, , ...paths] = pathname.split('/');
  const path = paths.join('/');
  const initialUrl = hasAccess ? `${unifyUrl}/${path?.includes('unify') ? '' : 'unify'}/${path}` : MARKETING_PAGE.UNIFY;
  const [embeddedUrl, setEmbeddedUrl] = useState(initialUrl);

  useEffect(() => {
    if (window.location?.href?.includes('livingsecurity.com')) {
      window.document.domain = 'livingsecurity.com';
    }

    // if initial path has search params, add them to the embeddedUrl
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.size > 0) {
      setEmbeddedUrl(`${embeddedUrl}?${searchParams.toString()}`);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!hasAccess && window.location?.pathname !== UNIFY_URLS.demo) {
      history.replace(UNIFY_URLS.demo);
    }
  }, [hasAccess, history]);

  return (
    <>
      <AuthorizedLayout app="UNIFY" withBottomPaddingForHubspot>
        <ErrorBoundary scope="unify-dashboard">
          <Iframe
            id="unify-embed-frame"
            allow="clipboard-read; clipboard-write *;"
            className="embedded-iframe"
            data-testid="unify-embed-frame"
            title="Living Security Unify"
            src={embeddedUrl}
          />
        </ErrorBoundary>
      </AuthorizedLayout>
    </>
  );
};

UnifyDashboard.propTypes = {
  history: PropTypes.any.isRequired,
};

export default UnifyDashboard;
